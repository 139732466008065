import { Component, OnDestroy, OnInit } from '@angular/core';


@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss']
})
export class AdminLayoutComponent implements OnInit,OnDestroy {

  ngOnInit() {
    // sessionStorage.setItem('access_token')  
   }

  ngOnDestroy(): void {
    // sessionStorage.removeItem('access_token')  
  }
}
