// import { Injectable } from '@angular/core';
// import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
// import { Observable } from 'rxjs';
import { JwtHelperService } from "@auth0/angular-jwt";
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
const helper = new JwtHelperService()

@Injectable({
    providedIn: 'root'
})
export class CanActivateGuard implements CanActivate {

    /**
     *
     */
    constructor(private router: Router) {


    }

    isLoggedIn(myRawToken) {
        try {
            const decodedToken = helper.decodeToken(myRawToken);
            console.log(decodedToken)
            const exp = helper.isTokenExpired(myRawToken)
            let date = Date.now()

            if (decodedToken && !exp) {
                // if (decodedToken.exp > date) {
                    console.log('real true')
                    return true
                // }
            }
            return false;
        }
        catch (err) {
            console.log(err)
            return false;
        }
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        
        if (sessionStorage.getItem('access_token')) {
            let isLogged = this.isLoggedIn(sessionStorage.getItem('access_token'))
            if (isLogged)
                return true;

        }
        alert('Un Authorized, please log in')
        this.router.navigate(['/login'])
    }
}