import { Component, OnInit } from '@angular/core';


export interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}

export const ROUTES: RouteInfo[] = [
    { path: '/panel/dashboard',     title: 'Dashboard',         icon:'nc-bank',       class: '' },
    {path:'/panel/auditions', title:'Audition',icon:'nc-bullet-list-67',class:''},
    {path:'/panel/clips', title:'Clip',icon:'nc-tv-2',class:''},
    {path:'/panel/equipments', title:'Equipment',icon:'nc-tv-2',class:''},
    {path:'/panel/users', title:'Users',icon:'nc-single-02',class:'Users'},
    {path:'/panel/skills', title:'Skills',icon:'nc-hat-3',class:'Skills'},
    {path:'/panel/genres', title:'Genres',icon:'nc-headphones',class:'Genre'},
    {path:'/panel/inspirations', title:'Inspirations',icon:'nc-favourite-28',class:'Skills'},
    {path:'/panel/reports', title:'Reports',icon:'nc-single-copy-04',class:'Skills'},

    // { path: '/icons',         title: 'Icons',             icon:'nc-diamond',    class: '' },
    // { path: '/maps',          title: 'Maps',              icon:'nc-pin-3',      class: '' },
    // { path: '/notifications', title: 'Notifications',     icon:'nc-bell-55',    class: '' },
    // { path: '/user',          title: 'User Profile',      icon:'nc-single-02',  class: '' },
    // { path: '/table',         title: 'Table List',        icon:'nc-tile-56',    class: '' },
    // { path: '/typography',    title: 'Typography',        icon:'nc-caps-small', class: '' },
    // { path: '/upgrade',       title: 'Upgrade to PRO',    icon:'nc-spaceship',  class: 'active-pro' },
];

@Component({
    moduleId: module.id,
    selector: 'sidebar-cmp',
    templateUrl: 'sidebar.component.html',
})

export class SidebarComponent implements OnInit {
    public menuItems: any[];
    ngOnInit() {
        this.menuItems = ROUTES.filter(menuItem => menuItem);
    }
}
