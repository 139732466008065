import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      "Authorization": sessionStorage.getItem('access_token')
    })
  };

  Login(email:string,password:string):Observable<any>{
    let obj = {
      email,password
    }
    return this.http.post<any>(`${this.httpService.httpServer}/admin/user/login`,obj)
  }

  getAllUsers():Observable<any[]>{
    return this.http.get<any[]>(`${this.httpService.httpServer}/admin/user`,this.httpOptions)
  }

  getUserByID(id:string):Observable<any>{
    return this.http.get(`${this.httpService.httpServer}/admin/user/${id}`,this.httpOptions)
  }

  getAllGenres():Observable<any>{
    return this.http.get(`https://finetuneapp.com/api/genre`)
  }

  updateUserByID(id:string,fullname:string,username:string,address:string,profiletype:string,email:string,description:string,birthdate:Date):Observable<any>{
    console.log({id,fullname,username,address,profiletype,email,description,birthdate:new Date(birthdate).toISOString()})
    
    return this.http.put(`${this.httpService.httpServer}/admin/user/${id}`,
    {id:+id,fullname,username,address,profiletype,email,description,birthdate:new Date(birthdate).toISOString()}
    ,this.httpOptions)
  }

  deleteUser(id:string):Observable<any>{
    return this.http.delete<any>(`${this.httpService.httpServer}/admin/user/${id}`,this.httpOptions)
  }

  constructor(private http:HttpClient,private httpService:HttpService) { }
}
