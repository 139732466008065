import { Routes } from '@angular/router';
import { LoginComponent } from './components/login/login.component';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { CanActivateGuard } from './services/auth.guard';

export const AppRoutes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  }, {
    path: 'panel',
    component: AdminLayoutComponent
    , canActivate: [CanActivateGuard],

    children: [
      {
        path: '',
        loadChildren: './layouts/admin-layout/admin-layout.module#AdminLayoutModule',
        // canActivate: [CanActivateGuard]
      }]
  },
  {
    path: '**',
    redirectTo: 'login'
  }
]
