import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  public httpServer:string = "https://admin.finetuneapp.com"
    // public httpServer:string = "http://localhost:3000"

  constructor() { }
}
