import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'app/services/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  public isLoading=false;
  onSubmit(email: string, password: string) {
    this.isLoading = true;
    this.userService.Login(email, password).subscribe(
      val => {
        sessionStorage.setItem('access_token',val)
        this.router.navigateByUrl('/panel/dashboard')
      },
      response => {
        alert("Please check your details and try again")
      },
      () => {
        this.isLoading = false;
      }

    )
  }

  constructor(private userService: UserService,
    private router:Router) { }

  ngOnInit(): void {
  }

}
